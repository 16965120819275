import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Technical Indicators`}</h1>
    <p>{`Source code at `}<a parentName="p" {...{
        "href": "https://github.com/dashed/technical_indicators"
      }}>{`github.com/dashed/technical_indicators`}</a></p>
    <p>{`Library of technical indicators in Rust.`}</p>
    <h2>{`What?`}</h2>
    <p>{`A technical indicator is a tool that attempts to forecast a technical analysis feature (e.g. support / resistance) based on given data such as historical price and volume.`}</p>
    <p>{`Typically, a trader or an investor would use technical indicators against an asset (e.g. stocks), and then perform technical analysis to formulate a trading (or investing) decision.`}</p>
    <p>{`See: `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Technical_indicator"
      }}>{`https://en.wikipedia.org/wiki/Technical_indicator`}</a></p>
    <br />
    <br />
    <hr />
    <br />
    <h2>{`Implemented indicators`}</h2>
    <p>{`Ichimoku Kinkō Hyō`}</p>
    <ImageSource src="technical-indicators/ichimoku.png" alt="Ichimoku Kinkō Hyō" mdxType="ImageSource" />
    <br />
    <p>{`Simple moving average`}</p>
    <ImageSource src="technical-indicators/sma.png" alt="Simple Moving Average" mdxType="ImageSource" />
    <br />
    <p>{`Exponential moving average`}</p>
    <ImageSource src="technical-indicators/ema.png" alt="Exponential Moving Average" mdxType="ImageSource" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      